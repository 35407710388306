@import "../../style/fonts";

// Login js Styles Start
.loginContainer {
  background-color: #fff;
  .loginFormMessage {
    align-items: flex-start;
    background-color: transparent;

    // width: 100%;
    // align-self: flex-start !important;

    // display: flex;
    // align-items: center;

    .anticon {
      font-size: 20px !important;
      padding-top: 1px !important;
    }
    .ant-alert-message {
      font-family: "tradeGothic";
      font-weight: 700;
    }
  }
}

.loginLeftSide {
  // overflow: hidden;
  position: relative;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 100% auto;
  // prevents layout shift on validation errors
  min-height: 720px; // accomodation for dynamic validation errors
}

.loginLogo {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.loginRightSide {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 40px 40px 40px;
  background: linear-gradient(180deg, #dcfbe7 0%, rgba(255, 255, 255, 0) 160px);
}

.loginFormContainer {
  width: 100%;
  max-width: 428px;
  background-color: transparent;
  & > :first-child {
    display: flex;
    align-self: center;
    width: 100%;
  }
}

.loginForm {
  & > * {
    margin-bottom: 12px;
    width: 100%;
  }
}

.loginFormDivider {
  margin-top: 14px;
  margin-bottom: 14px;
}

.forgotPasswordText u {
  font-size: 1rem;
  font-family: tradeGothic, Roboto, sans-serif;
}
// Login js Styles End

// Testimonial.JS Styles Card Start
.testimonialContainer {
  max-width: 460px;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 20px;
  color: #fff;
  border-radius: 14px;
}

.testimonialDescription {
  font-family: TradeGothic;
  font-size: 1.25rem;
  line-height: 1.25;
  font-weight: 400;
}

.testimonialTitle {
  font-family: TradeGothic;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25;
}

.testimonialSubtitle {
  font-size: 1rem;
  font-weight: 400;
  font-family: TradeGothic;
}
// Testimonial.JS Styles Card End
