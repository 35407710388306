.accountsContainer {
}

.checkboxRegular {
  display: flex;
  align-items: center;

  & :nth-child(2) {
    padding-top: 4px;
    font-weight: 500;
  }
}

