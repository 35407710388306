.flatFormItem,
.flat {
  .ant-form-item-label {
    padding-bottom: 6px;
    label {
      line-height: 1;
      font-size: 1rem !important;
      padding-left: 0px;
      font-family: tradeGothic !important;
    }
    span {
      line-height: 1;
      font-size: 1rem !important;
      padding-left: 0px;
      font-family: tradeGothic !important;
      font-weight: 700;
    }
  }
  // for the input fields with addOns
  .ant-input-group-wrapper {
    padding: 0px !important;
    input {
      padding: 12px;
    }
  }

  // underlined fields
  &.underlined {
    input,
    textarea {
      background-color: #f4f4f4 !important;
      border: 0px;
      border-bottom: 1px solid #d1d5db;
    }
    .ant-input-password {
      background-color: #f4f4f4 !important;
      border: 0px;
      border-bottom: 1px solid #d1d5db;
      input {
        border: none;
      }
    }
    &.select {
      .ant-select-selection-search-input {
        height: 40px;
      }
      .ant-select-selection-item {
        font-size: 14px !important;
        position: absolute;
        top: 5px;
      }

      .ant-select-selection-placeholder {
        font-size: 14px !important;
        color: #a8acb3 !important;
        position: absolute;
        top: 5px;
      }

      .ant-form-item-control-input {
        background-color: #f4f4f4 !important;
        border: 0px;
        height: 47px;
        border-bottom: 1px solid #d1d5db;
      }

      .ant-select-selector {
        padding-top: 10px;
        background-color: transparent !important;
      }
    }
  }

  &.underlinedDatePicker {
    .ant-picker {
      width: 100%;
      border-bottom: 1px solid #d1d5db !important;
      height: 48px;
      background-color: #f4f4f4;
      box-sizing: border-box;
    }
  }

  .react-tel-input {
    height: 47px;
    width: 100%;
    input {
      height: 100%;
      width: 100%;
    }
    .flag-dropdown {
      border: none;
      border-bottom: 1px solid #d1d5db;
    }
  }

  & .ant-input-group-addon {
    border-top: 0;
    border-left: 0;
    border-right: 0;
    background-color: #f4f4f4;
  }
  &:not(.ant-form-item-has-error) .ant-input-group-addon {
    background-color: #f4f4f4;
    border: 0px;
    border-bottom: 1px solid #d1d5db;
  }
}

.flatFormItemCheckboxGroup {
  display: flex;
  // align-items: center;
  // margin-bottom: 0px;
  .ant-checkbox-wrapper {
    & > :nth-child(2) {
      align-self: center;
    }
  }
}

.flatFormItemCheckbox {
  display: flex;
  align-items: center;
  // margin-bottom: 0px;
  .ant-checkbox-wrapper {
    & > :nth-child(2) {
      align-self: center;
    }
  }
}

.flatFormItem.readOnly {
  cursor: default !important;
  & * {
    cursor: default !important;
  }
}

.addondisabled {
  opacity: 0.4;
  pointer-events: none;
}

.label-d-none {
  .ant-form-item-label {
    display: none !important;
  }
  label {
    padding: 0px !important;
  }
}

.no-error-field {
  .ant-form-item-explain {
    display: none !important;
  }
}

.underlinedDatePicker {
  .ant-picker-status-error.ant-picker,
  .ant-picker-status-error.ant-picker {
    border-bottom: 1px solid #ff7875 !important;
  }
}
