//  ThankYouBooking.js start
.thankyouContainer {
  padding: 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 120px - 280px);
  background: linear-gradient(180deg, #dcfbe7 0%, rgba(255, 255, 255, 0) 136px);

  .ant-collapse-header {
    // // padding-top: 0px !important;
    padding-bottom: 6px !important;
    padding-right: 0px !important;
  }

  .accordionHeaderMeta {
    padding-right: 0px;
  }

  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.thankYouWrapper {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}

.thankyouName {
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.height100 {
  height: 100% !important;
}

.thankyouBasicInfo {
  line-height: 1.4;
  flex: 1;
  display: inline-flex;
  & span {
    font-family: "tradeGothic";
    font-size: 17px;
    color: #000;
  }
}

.thankyouSummary {
  .accordionHeaderMeta {
    padding-bottom: 0px;
  }
  .ant-collapse-header {
    padding-bottom: 0px !important;
  }
}
.thankyouAccordion {
  border-bottom: 1px solid #e4ebe7;
}

.thankyouBookingDate {
  & > * {
    line-height: 1;
  }
}

.thankyouSlide {
  padding-top: 8px;
  .horizontalSlide {
    // padding-top: 12px;
    // padding-bottom: 12px;
  }
}

.thakyouCancelNotice {
  padding-top: 10px;
  padding-bottom: 18px;
  border-bottom: 1px solid #e4ebe7;
}

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}
// ThankYouBooking.js end

// BookingPayment.js Start
.miniPaymentCard {
  display: flex;
  align-items: center;
  .cardChip {
    display: inline-block;
    width: 36px;
    height: auto;
    margin-right: 8px;
  }
  .issuer {
    font-weight: 700;
    text-transform: capitalize;
  }
}

.couponsRow {
  .couponsRowApplyBtn {
    margin-top: 45px;
    display: inline-block;
  }
}

.voucherAppliedContainer {
  margin-top: 38px;
}

.checkMarkOutlined {
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #27ca2d;
  svg {
    width: 12px;
  }
  path {
    stroke: #27ca2d;
  }
}

.availedText {
  line-height: 1 !important;
  font-size: 18px !important;
  color: #27ca2d !important;
  margin-bottom: 0px !important;
}

.voucherCloseBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  background-color: #f4f4f4 !important;
  border: #f4f4f4;
  margin-left: 12px;
}

// BookingPayment.js End

// StripePayment.js start
.stripeForm {
  .ant-form-item-explain-error {
    display: none;
    min-height: none;
  }
}

.stripeFormCheckbox {
  margin-top: 8px;
  span {
    font-weight: 400;
  }
}
// StripePayment.js end

// Invite Friendcard Start

.inviteFriendCard {
  display: flex;
  align-items: flex-start;
  position: relative;
  padding: 20px 0px 16px 0px;
}
.inviteFriendCardImage {
  width: 180px;
  position: relative;
  z-index: 1;
}

.inviteFriendCardContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 28px;
  margin-left: -60px;
  background-color: #e6e9ee;
  padding: 20px 36px 16px 60px;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  width: 100%;
  & > *:first-child {
    max-width: 500px;
  }
}
// Invite Friendcard End
.serviceModal {
  .ant-modal-content,
  .ant-modal-content {
    border-radius: 8px !important;
  }
}

.orderTotal {
  display: flex;
  justify-content: space-between;
  max-width: 264px;
  width: 100%;
}
