@font-face {
  font-family: "hendersonBasic";
  src: url("../../assets/fonts/HendersonSansBasicLight.otf");
}
@font-face {
  font-family: "hendersonBold";
  src: url("../../assets/fonts/HendersonSansBasicBold.otf");
}
@font-face {
  font-family: "hendersonSemiBold";
  src: url("../../assets/fonts/HendersonSansBasicSemiBold.otf");
}
@font-face {
  font-family: "rift";
  src: url("../../assets/fonts/Fort\ Foundry\ -\ Rift-Regular.otf");
}
@font-face {
  font-family: "tradeGothic";
  src: url("../../assets/fonts/Trade\ Gothic\ LT.ttf");
}
@font-face {
  font-family: "tradeGothicBold";
  src: url("../../assets/fonts/Trade\ Gothic\ LT\ Bold\ No.\ 2.ttf");
}

.hendersonBasic {
  font-family: "hendersonBasic";
}
.hendersonBold {
  font-family: "hendersonBold";
}
.hendersonSemiBold {
  font-family: "hendersonSemiBold";
}
.rift {
  font-family: "rift";
}
.tradeGothic {
  font-family: "tradeGothic" !important;
}
.tradeGothicBold {
  font-family: "tradeGothicBold" !important;
}

.bold {
  font-weight: bold;
}

// input placeholder colors
::placeholder {
  color: #a8acb3 !important;
}

//common
.info {
  font-size: 22px;
  padding-bottom: 1px;
  font-style: italic;
  font-weight: bold;
  font-family: serif;
  box-sizing: border-box;
  overflow: hidden;
  line-height: 1;
  height: 30px;
  width: 30px;
  min-height: 30px;
  max-height: 30px;
  min-width: 30px;
  max-width: 30px;
  border-radius: 50%;
  background-color: #e5e8ee;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.flex {
  display: flex;
}
.justifyStart {
  justify-content: flex-start;
}
.justifyEnd {
  justify-content: flex-end;
}
.justifySpaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}
.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.colorPrimary {
  color: #486baf !important;
}

.colorSuccess {
  color: #27ca2d !important;
}

.colorWarning {
  color: #fa8c16 !important;
}

// customizing default layout
section.ant-layout {
  background-color: #fff;
}

// customizing Antd Title
.ant-typography {
  color: #374151;
}
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-family: hendersonBasic, Roboto, sans-serif;
  color: #15213b;
}

span.ant-typography {
  font-size: 16px;
}

a.ant-typography {
  font-family: "tradeGothic";
  font-size: 16px;
  & > * {
    font-size: inherit;
    font-family: inherit;
  }
}

// customizing Antd Button type="text"
button.ant-btn {
  span {
    font-family: inherit;
  }
}
button.ant-btn-text {
  height: fit-content;
  padding: 0px;
  margin: 0px;
  border: none;
  background-color: transparent !important;
  span {
    color: #486baf;
    text-decoration: underline;
    font-family: "tradeGothic";
    font-size: 16px;
  }
}

// available tags
.primaryTag {
  margin: 0;
  color: #fff;
  background: #486baf;
  border-radius: 50px;
  padding: 4px 14px;
  padding-right: 18px;
  svg {
    margin-right: 8px;
  }
  span {
    font-family: "tradeGothic";
    font-weight: 700;
  }
}

// customizing modal
.antdModal {
  border-radius: 10px;
  overflow: hidden;

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-header {
    padding: 26px 30px;
    padding-top: 30px;
    .ant-modal-title {
      color: #000;
      font-family: "hendersonBasic";
      font-weight: 600;
      font-size: 1.25rem;
    }
  }

  .ant-modal-body {
    padding: 24px 30px;
    max-height: calc(100vh - 100px - 80px - 75px);
    overflow-y: auto;
    // &::-webkit-scrollbar {
    //   width: 5px;
    //   height: 8px;
    //   background-color: #eceff6;
    //   // background-color: #aaa;
    // }
    // &::-webkit-scrollbar-thumb {
    //   background: #a5b3cf;
    // }
  }

  .ant-modal-close {
    top: 16px;
    right: 10px;
  }

  &.headerBorder {
    .ant-modal-header {
      border-bottom: 2px solid #d4dbe9;
    }
  }

  &.noHeaderBorder {
    .ant-modal-header {
      border-bottom: none;
      padding-bottom: 20px;
    }
    .ant-modal-body {
      padding: 24px 30px;
      padding-top: 0px;
    }
  }

  &.hasFooter {
    .ant-modal-body {
      padding-bottom: 10px;
    }
    .ant-modal-footer {
      padding: 0px 30px 24px 30px;
      padding-top: 10px;
      border-top: none;
    }
  }
}

// historyFormWrapper styles
.historyForm {
  .ant-modal-footer {
    padding: 10px !important;
  }
}
.intakeForm {
  .ant-modal-body {
    padding-top: 12px;
  }
  .ant-modal-footer {
    text-align: left;
  }
}

.intakeFormWrapper {
  .flatFormItem .ant-form-item-label {
    padding-bottom: 10px;
  }
  .flatFormItem label {
    padding-top: 6px;
    line-height: 1.3 !important;
    font-weight: 400 !important;
  }
  .ant-checkbox-wrapper :nth-child(2) {
    font-weight: 400 !important;
  }
}

.contradictionsChecboxes {
  .ant-checkbox-wrapper {
    min-width: 200px;
    // width: calc(33.33% - 8px);
  }
}

.diseasesCheckboxes {
  .ant-checkbox-wrapper {
    min-width: 224px;
    // width: calc(33.33% - 8px);
  }
}
.ant-slider-handle {
  background-color: #69c0ff;
  border-color: #69c0ff !important;
}
.ant-slider-dot-active {
  background-color: #69c0ff;
  border-color: #69c0ff;
}

.ant-slider-track {
  background-color: #69c0ff !important;
  // height: 6px;
}

.ant-slider-rail {
  // background-color: #69C0FF;
  // height: 6px;
}

.showScrollbar {
  scroll-behavior: smooth;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    height: 1px;
    background-color: #eceff6;
    // background-color: #aaa;
  }
  &::-webkit-scrollbar-thumb {
    background: #a5b3cf;
  }
}

// dashboard popup boost
.boostPopup {
  .ant-modal-body {
    padding-bottom: 12px;
  }
}

// add to calendar styles
.atcb_bgoverlay {
  cursor: default !important;
}

// misc start
.link {
  cursor: pointer;
}
// misc end

.ant-select-arrow .anticon-loading {
  font-size: 17px;
  color: #486BAF;
}

.ant-empty-description {
  font-family: tradeGothic;
}

.ant-btn-text:disabled {
  cursor: default;
  span {
    color: #ddd;
  }
}
.ant-select-item-option-disabled{
  div{
    color: #999 !important;
  }
}
.ant-tooltip-inner{
  background-color: #000 !important;
}

