.zipCodeInput {
  overflow: hidden;
}
.zipCodeInput input {
  border-radius: 0px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
}

.roundedFormItem {
  height: 40px;
  border-radius: 14px !important;
  border: none;
  font-family: "tradeGothic" !important;
  color: #666 !important;

  &:focus {
    border: none;
    box-shadow: none !important;
  }
  .ant-select-selector {
    height: 40px !important;
    border-radius: 8px !important;
  }
  .ant-form-item {
    color: #666 !important;
  }
  .ant-select-selector {
    color: #666 !important;
  }
  .ant-input-number-input {
    color: #666 !important;
  }
  .ant-input {
    background-color: #000 !important;
  }
  &.ant-form-item-has-error {
    border-color: red;
  }
}
.roundedFormItem.input {
  .ant-input-wrapper {
    input {
      background: transparent !important;
      border: none !important;
      font-family: "tradeGothic";
      font-size: 20px;
      color: #666 !important;
      margin-top: -5px;
      outline: none !important;
      box-shadow: none !important;
    }
  }
  .ant-input-group-addon {
    border: none !important;
    background: transparent !important;
  }

  padding-bottom: 16px !important;

  &::placeholder {
    color: #bfbfbf !important;
    font-size: 18px;
    font-family: sans-serif; // rest of the form has san-serif
  }
}
.ant-select-selection-item {
  span {
    font-size: 20px !important;
    font-family: "tradeGothic" !important;
    color: #000 !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  box-shadow: none !important;
  border: none !important;
}

.ant-picker,
.ant-picker-focused {
  border: none !important;
  box-shadow: none !important;
}
.ant-select-item-option-content {
  font-family: "tradeGothic" !important;
  font-size: 16px;
}

.home_select {
  border: none;
  height: 40px;
  font-size: 18px !important;
  color: #000 !important  ;
  font-family: "hendersonBold" !important;

  .ant-picker {
    border: none !important;
  }
  .ant-picker-focused {
    background-color: red !important  ;
  }
  .ant-select-selector {
    height: 40px !important;
    border-radius: 14px !important;
    border: none !important;
    overflow: auto !important;
    width: 200px;
    .ant-select-selection-overflow {
      flex-wrap: nowrap;
    }
  }
  .ant-form-item {
    color: #666 !important;
  }
  .ant-select-selector {
    color: #666 !important;
  }
  .ant-input-number-input {
    color: #666 !important;
  }
  .ant-select-dropdown {
    width: 192px;
  }
}

label {
  font-size: 15px !important;
  font-weight: bold !important;
  padding-left: 10px;
  padding-top: 8px;
  color: black !important;
  font-family: "hendersonSemiBold" !important;
}
// .ant-select .home_select .ant-select-single .ant-select-show-arrow{

// }

.ant-select-multiple .ant-select-selection-item {
  background-color: #fff !important;
  border: none !important;
  font-size: 18px !important;
}
#date {
  font-size: 18px !important;
}

.ant-select-selector {
  border: none !important;
  font-size: 18px;
  background-color: #fff !important;
  .ant-select-selection-overflow {
    flex-wrap: nowrap !important;
    white-space: nowrap !important;
    overflow: auto !important;
    text-overflow: ellipsis !important;
    margin: 0px !important;
    .ant-select-selection-overflow-item {
      .ant-select-selection-item-content {
        margin: 0px !important;
        color: #666 !important;
      }
    }
  }
}

.ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-inner {
  width: 32px !important;
  height: 32px !important;
  border-radius: 10px !important;
}
.ant-checkbox-inner::after {
  height: 13.142857px !important;
  width: 7.714286px !important;
  left: 8px !important;
}

element.style {
  width: 5px;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  font-family: "Roboto", sans-serif;
}
user agent stylesheet div {
  display: block;
}
.ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  overflow: auto !important;
  text-overflow: ellipsis !important;
  margin: 0px !important;
  color: #666 !important;
}

element.style {
  width: 5px;
}
.ant-select-multiple .ant-select-selection-search {
  position: relative;
  max-width: 100%;
  margin-inline-start: 7px;
}
.ant-layout,
.ant-layout * {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
* {
  font-family: "Roboto", sans-serif;
}
user agent stylesheet div {
  display: block;
}
.ant-select-selector .ant-select-selection-overflow {
  flex-wrap: nowrap !important;
  white-space: nowrap !important;
  overflow: auto !important;
  text-overflow: ellipsis !important;
  margin: 0px !important;
  background-color: #fff;
  border: none;
  color: #666 !important;
}
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
  cursor: pointer !important;
}
.ant-select-multiple.ant-select-disabled.ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  background-color: #fff !important;
}
#boosts {
  cursor: pointer !important;
  background-color: #fff !important;
}

.hideValue {
  .ant-select-selection-item {
    opacity: 0;
  }
}

.showValue {
  .ant-select-selection-item {
    opacity: 1;
  }
}

.noError {
  .ant-form-item-explain {
    display: none !important;
  }
}

.roundedSelect {
  border: 2px solid #dbdfea;
  &.ant-form-item-has-error {
    border-color: #ff4d4f !important;
  }
  .ant-picker.ant-picker-disabled {
    cursor: pointer !important;
    background-color: transparent !important;
    & * {
      cursor: pointer !important;
    }
  }
}

.timeDropdown {
  width: 192px !important;
  .ant-picker.ant-picker-disabled {
    cursor: pointer !important;
    background-color: transparent !important;
    & * {
      cursor: pointer !important;
    }
  }
}

.rc-virtual-list-holder {
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    height: 1px;
    background-color: #eceff6;
    // background-color: #aaa;
  }
  &::-webkit-scrollbar-thumb {
    background: #a5b3cf;
  }
}
