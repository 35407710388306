.horizontalSlideContainer {
  position: relative;

  .arrowLeft {
    top: 50%;
    transform: translate(0, -50%);
    left: -16px;
  }
  .arrowRight {
    top: 50%;
    transform: translate(0, -50%);
    right: -16px;
  }
}

.horizontalSlide {
  display: flex;
  padding-bottom: 12px;
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 5px;
    height: 1px;
    background-color: #eceff6;
    // background-color: #aaa;
  }
  &::-webkit-scrollbar-thumb {
    background: #a5b3cf;
  }
}

.controller {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  background-color: #486baf;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  box-shadow: 0 0 5px #888;
  z-index: 10;
  border: 0;
  outline: 0;
  box-shadow: none;
}
