.documentsSearchbar {
  padding: 13px;
  font-size: 17px;
  line-height: 1;
  .ant-input-prefix {
    margin-right: 8px;
  }
}

.documentsList {
  .ant-list-header {
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #eff1f4;
    border-bottom: 1px solid #eff1f4;
  }
}

.documentListItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0px;
  border-bottom: 1px solid #eff1f4;
  line-height: 1;

  & > :first-child{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-right: 8px;
  }
  & > :nth-child(2){
    white-space: nowrap;
  }

}
