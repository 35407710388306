footer.appFooter {
  text-align: center;
  background: #151e33;
  height: 280px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.footerLinkContainer {
  display: flex;
  justify-content: center;
  font-size: 16px;
  a,
  p {
    color: #fff !important;
    margin: 8px;
    font-family: rift;
    font-size: 20px;
  }
}

.footerSocialIconsContainer {
  display: flex;
  justify-content: center;
  a {
    color: #fff !important;
    font-size: 32px;
  }
}

.logo {
  height: 40px;
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  margin-left: 20px;
  img {
    height: 40px;
    width: 40px;
    margin-right: 5px;
  }
}

.footerLink {
  color: #fff !important;
  &:hover {
    color: #fff !important;
  }
}
