.familyMembersList {
  padding-top: 12px;
  .ant-list-item {
    padding: 0;
    margin-bottom: 20px;
    border-bottom: none;
  }
}

.addNewMemberContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98px;
  border: 2px dashed #dfdfdf;
  background: #fcfcfc;
  border-radius: 4px;
  cursor: pointer;
  & > * {
    line-height: 1;
    margin: 0 !important;
  }
}

// FamilyMemberCard.js Start
.famileMemberCard {
  padding: 32px;
  border: 2px solid #dfdfdf;
  border-radius: 4px;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.07);
}
// FamilyMemberCard.js End

// FamilyMemberModal.js Start
.familyPermissionCheckbox {
  span {
    font-weight: 400;
  }
  & span:nth-child(2) {
    padding-top: 6px;
    padding-left: 10px;
  }
}
// FamilyMemberModal.js End
