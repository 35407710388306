.servicesCategory {
  font-family: "hendersonSemiBold";
  font-size: 16px;
  cursor: pointer;
  margin: 0;
  padding: 8px;
  border-radius: 8px;
  &:hover {
    background-color: #f2f5fc;
  }
  &.selected {
    background-color: #f2f5fc;
  }
}

@media (max-width: 575px) {
  .dashboardSelector {
    & > * {
      flex: 1 1 0% !important;
    }
  }
}

.dashboardSelector {
  ::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
}

// Mobile dropdown CSS Start
.mobileServiceDropdown {
}

// searchbar styles start
.mobileServiceDropdownSearchContainer {
  padding: 12px;
}
.mobileServiceDropdownSearch {
  padding: 10px;
  border-radius: 4px;
  border-color: #d5deee;
  .ant-input {
    padding-top: 2px !important;
    font-size: 16px;
    line-height: 1;
  }
  ::-webkit-input-placeholder {
    /* Edge */
    padding-top: 2px !important;
    font-size: 16px;
    line-height: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    padding-top: 2px !important;
    font-size: 16px;
    line-height: 1;
  }

  ::placeholder {
    padding-top: 2px !important;
    font-size: 16px;
    line-height: 1;
  }
}
// searchbar styles end


// collapse start
.mobileServiceDropdownCollapse{
  .ant-collapse-header{
    box-shadow: 0px 1px 10px #e6e6e6;
    background-color: #F9F9FB;
    border-color: #D5DEEE;
    font-family: tradeGothic;
    color: #1a1a1a !important;
  }
}
// collapse end
// Mobile dropdown CSS End
