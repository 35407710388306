// Accounts.js start
.accountsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 120px - 280px);
  background: linear-gradient(180deg, #dcfbe7 0%, rgba(255, 255, 255, 0) 136px);
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.accountsWrapper {
  display: flex;
  width: 100%;
  max-width: 1350px;
  margin: 0 auto;
}
// Accounts.js end

// Sidebar.js start
.menuDropdown{
  width: 100%;  
}


// Sidebar.js end


// Common Styles for Accounts Page
.accountsCommonTitle{
  padding-top: 14px;
  padding-bottom: 24px;
  margin-bottom: 0px;
  border-bottom: 2px solid #CDDFD5;
}