.giftcardsAndPromotionsLeftSideWrapper {
  & > * {
    max-width: 420px;
  }
}

.max-w-auto{
  max-width: 100% !important;
}

.creditsWidget {
  background-color: #e6e9ee;
  border-radius: 8px;
  min-width: 260px;
  max-width: 284px;
  width: 100%;

  padding: 16px 20px 20px 20px;
  .ant-statistic-title {
    font-size: 17px;
    font-family: "tradeGothicBold";
    color: #15213b;
    margin-bottom: 14px;
  }
  .ant-statistic-content {
    line-height: 1;
    span {
      color: #486baf !important;
      font-family: "tradeGothic" !important;
      font-weight: 700;
      font-size: 30px;
    }
  }
}


.promoGiftCardValues{
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .ant-radio-wrapper {
    margin: 0px;
    border-radius: 4px;
    background-color: #f3f5f9;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #e3e5e9;
    }

    &.ant-radio-wrapper-checked {
      background-color: #e3e5e9;
    }

    .ant-radio-inner {
      display: none;
    }

    // text of radio btn
    & > :nth-child(2) {
      font-weight: 400;
      font-family: "tradeGothic";
      font-size: 17px;
    }
  }
}
// GiftCardModal.js Start
.giftCardValues, .promoGiftCardValues {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  .ant-radio-wrapper {
    margin: 0px;
    border-radius: 4px;
    background-color: #f3f5f9;
    height: 45px;
    width: 98px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #e3e5e9;
    }

    &.ant-radio-wrapper-checked {
      background-color: #e3e5e9;
    }

    .ant-radio-inner {
      display: none;
    }

    // text of radio btn
    & > :nth-child(2) {
      font-weight: 400;
      font-family: "tradeGothic";
      font-size: 17px;
    }
  }
}
.promoGiftCardValues{
  .ant-radio-wrapper {
    width: 250px;
    justify-content: flex-start;
    padding-left: 16px;
  }
}

.giftCardModal {
  h4 {
    font-size: 18px;
  }
  .ant-modal-body {
    padding-top: 18px;
  }
}

.giftCardModal {
  .tradeGothic {
    font-family: tradeGothic;
  }
  * {
    font-family: tradeGothic;
  }
}

.firstLetterUppercase {
  text-transform: lowercase;
}
.firstLetterUppercase::first-letter {
  text-transform: uppercase;
}

.giftCardsList {
  & > * {
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 14px;
    margin-bottom: 14px;
  }
}

.giftcardFeedbackAlert {
  margin-bottom: 10px;
}


.gcAndPromoControls{
  @media screen and (max-width: 768px) {
    button{
      padding-left: 20px !important;
      padding-right: 20px !important;
    }
    
  }
}