.cardDetails {
    display: flex;
    align-items: center;
    margin-left: 8px;
  
    .cardBrandName {
      font-weight: 700;
      &::first-letter {
        text-transform: capitalize;
      }
    }
  
    & > * {
      margin-right: 8px;
    }
  }
  
  .ml-12 {
    margin-left: 6px;
  }
  