.appointmentsTab {
  .ant-tabs-tab {
    padding: 4px 16px 20px 16px;
  }

  .ant-tabs-tab-btn {
    line-height: 1;
    font-weight: 400;
    font-size: 1.25rem;
    font-family: "tradeGothic";
    color: #15213b;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      font-weight: 700;
      color: #15213b;
    }
  }

  .ant-tabs-ink-bar {
    height: 4px !important;
  }

  .ant-tabs-nav::before {
    border-bottom: 1px solid #cddfd5;
  }
}

.appointmentsList {
  li.ant-list-item {
    padding: 0px;
    border-bottom: 0px !important;
    padding-bottom: 16px;
    &:last-child {
      padding-bottom: 0px;
    }
  }
}



// AppointmentsCard.js start
.appointmentCardContainer {
  padding-bottom: 16px;
  border-bottom: 2px solid #cddfd5;
  .ant-space-item {
    display: inline-flex;
  }
}
// AppointmentsCard.js end
