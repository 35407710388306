// IntakeForms.js Styles Start
.intakeFormsList {
  .ant-list-item {
    padding: 0;
    margin-bottom: 16px;
    border-bottom: none;
  }
  &.minimal{
    .ant-list-item:not(:last-child) { 
      margin-bottom: 12px;
    }
    .ant-list-item:last-child { 
      margin-bottom: 0px;
    }
  }

}
// IntakeForms.js Styles End

// IntakeFormCard.js Styles Start
.intakeFormCardContainer:not(.minimal){
  padding-bottom: 16px;
  border-bottom: 1px solid #cddfd5;
  // border-bottom: 3px solid green !important;
}
// IntakeFormCard.js Styles End
// AuditListItem Start
.auditListItem {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: flex-start;
}
.auditListItemLeft {
  display: flex;
  flex-direction: column;
}
// AuditListItem End
// intakeForm Modal start