// Accordion.js start
@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.opacity0 {
  animation: opacity 1s reverse;
  animation-fill-mode: both;
  animation-timing-function: ease-in;
  @media screen and (max-width: 768px) {
    animation: none;
  }
}
.opacity1 {
  animation: opacity 0.725s;
  animation-fill-mode: both;
  animation-timing-function: ease-in;

  @media screen and (max-width: 768px) {
    animation: none;
  }
}
.accordion {
  background-color: transparent;
  .ant-collapse-header {
    background-color: transparent;
  }
  .ant-collapse-header-text{
    width: 100%;
  }

  .ant-collapse-content {
    border-top: 0px;
  }

  .ant-collapse-content-box {
    padding-top: 0px !important;
    margin-top: -60px;
    padding-left: 262px; // 250px + 12px
    @media screen and (max-width: 768px) {
      padding-left: 0px;
      margin-top: 0px;
    }
  }

  .accordionContent {
    position: relative;

    z-index: 1;
    padding: 0px 20px 20px 20px;
  }

  .accordionHeader {
    gap: 0 !important;
    width: 100%;
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
    }
    & > :nth-child(2) {
      display: flex;
      width: 100%;
    }
  }

  .accordionHeaderContent {
    width: 252px;
    & .ant-avatar {
      display: flex;
      align-items: center;
      height: 60px;
      width: 60px;
      background-color: #486baf80;
      & .ant-avatar-string {
        font-size: 1.25rem;
        font-weight: 700;
        font-family: "tradeGothic";
      }
    }
    & .ant-typography {
      font-size: 1.25rem;
      font-family: "tradeGothic";
      font-weight: 700;
    }
  }

  .accordionHeaderMeta {
    padding: 20px;
    width: 100%;
  }
}

.signupAddonAfterPrimaryText {
  color: #486baf;
  .ant-typography {
    color: #486baf;
    font-weight: 700;
    font-size: "tradeGothicLT";
  }
}

.unclickable {
  .ant-collapse-header {
    cursor: default !important;
  }
}

// Accordion.js end
