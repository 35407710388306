.addressesList {
  .ant-list-item {
    padding: 0;
    margin-bottom: 16px;
  }
}

// AddressCard.js Styles Start
.addressCardContainer {
  padding-bottom: 16px;
  border-bottom: 1px solid #cddfd5;
}

.addressCardDetails {
  font-family: "tradeGothic";
  margin-bottom: 0px;
  font-size: 17px;
  font-weight: 500;
  display: inline-block;
  margin-right: 24px;
}


// AddressCard.js Styles End
