.radio {
  & > * {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: "tradeGothic";
  }
  .ant-radio {
    top: 8px !important;
  }
  .ant-radio-inner {
    background-color: #dbdfea !important;
    border: none !important;
    height: 32px !important;
    width: 32px !important;
    &::after {
      border-radius: 20px;
      height: 32px !important;
      width: 32px !important;
      margin-left: -16px !important;
      margin-top: -16px !important;
    }
  }
}
