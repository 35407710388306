// Membership.scss start
.availableMembershipsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 120px;

  &.hasMembership {
    justify-content: flex-start;
    padding-top: 10px;
    padding-bottom: 28px;
  }
}
// Membership.scss end

// MembershipCard.js Start
.membershipCard {
  width: 240px;
  height: 280px;
  box-sizing: border-box;
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.07);
  border: 2px solid #dfdfdf;
  border-radius: 8px;
  padding: 20px;
  padding-top: 16px;
  cursor: pointer;
  transition: box-shadow 0.125s ease-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  }
  .content {
    //   margin-bottom: 32px;
    overflow: hidden;
  }
  .innerContent {
    margin-bottom: 16px;
    & * {
      font-family: "tradeGothic";
      display: block;
      color: #161f3a;
    }
  }
}
// MembershipCard.js End

// MembershipModal.js Start
.membershipModal {
  .ant-modal-footer {
    border-top: 2px solid #d4dbe9 !important;
  }
  .membershipSaveButton {
    span {
      font-family: "tradeGothicBold";
    }
  }
}
.membershipModalContent {
  .content {
    margin-bottom: 32px;
    // max-width: 200px;
    .header {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
  .innerContent {
    margin-bottom: 16px;
    & * {
      font-family: "tradeGothic";
      display: block;
      color: #161f3a;
    }
  }
}

.membershipModalFooter {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 16px;
}
// MembershipModal.js End
.membershipRibbon {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-top-right-radius: 4px;
  top: 0px;
  .ant-ribbon-text {
    font-family: "tradeGothic";
    font-weight: 700;
  }
}
