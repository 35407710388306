//  Signup.js start
.signupContainer {
  padding: 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 120px - 280px);
  background: linear-gradient(180deg, #dcfbe7 0%, rgba(255, 255, 255, 0) 136px);
  @media screen and (max-width: 768px) {
    padding: 16px;
  }
}

.signupWrapper {
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
}

.height100 {
  height: 100% !important;
}

.signupUserInfo {
  line-height: 1.4;
  & span {
    font-family: "tradeGothic";
    font-size: 17px;
  }
}
// Signup.js end



// StripePayment.js start
.stripeForm {
  .ant-form-item-explain-error {
    display: none;
    min-height: none;
  }
}

.stripeFormCheckbox {
  margin-top: 8px;
  span {
    font-weight: 400;
  }
}

// StripePayment.js end
