.gc {
  padding: 40px;
  box-sizing: border-box;
  min-height: calc(100vh - 120px - 280px);
  background: linear-gradient(180deg, #dcfbe7 0%, rgba(255, 255, 255, 0) 136px);

  .gcContainer {
    max-width: 1000px;
    margin: 0 auto;
  }
}

.gcForm {
  .tradeGothic {
    font-family: tradeGothic;
    * {
      font-family: tradeGothic;
    }
  }
}

// promo card sliders start
.promoGiftCardSliders {
  .horizontalSlide {
    gap: 16px;
  }
}
// promo card sliders end

// promo gift card start
.promoGiftCard {
  width: 200px;
  min-width: 200px;
  height: 280px;
  max-height: 280px;
  box-sizing: border-box;
  // box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.07);
  border: 2px solid #dfdfdf;
  border-radius: 8px;
  padding: 20px;
  padding-top: 16px;
  cursor: pointer;
  transition: box-shadow 0.125s ease-out;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.selected {
    background-color: rgb(74, 106, 175);
    color: #fff;
    * {
      color: #fff !important;
    }
  }

  &:hover {
    box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.1);
  }
  .content {
    //   margin-bottom: 32px;
    overflow: hidden;
    overflow-y: auto;
  }
  .innerContent {
    margin-bottom: 16px;
    & * {
      font-family: "tradeGothic";
      display: block;
      color: #161f3a;
    }
  }
}

// promo gift card end
