.appLoader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10000001;
  display: flex;
  height: 100vh;
  width: 100%;
  align-items: center;
  justify-content: center;
  opacity: 100;
  transition: opacity 0.35s ease-in-out;
  background-color: #fff;
  &.fade {
    opacity: 0;
  }
  &.hidden {
    display: none;
  }

  img{
    width: 280px
  }
}
