* {
  font-family: "Roboto", sans-serif;
}

// customizing default layout
section.ant-layout {
  background-color: #fff;
}

// customizing Antd Title
h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
h4.ant-typography,
h5.ant-typography,
h6.ant-typography {
  font-family: hendersonBasic, Roboto, sans-serif;
  color: #000;
}

.whiteSpacePreWrap {
  white-space: pre-wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.gap-16 {
  gap: 16px;
}
