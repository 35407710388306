.button {
  &.rounded {
    width: fit-content;
    border-radius: 50px;
    padding-left: 45px;
    padding-right: 45px;
  }
}
// Flat Buttons Start
.flatButtonFilled {
  height: auto;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 1;
  background-color: #486baf;
  // border: 2px solid #486baf;
  color: #fff;
  border-radius: 6px;
  font-family: "tradeGothicBold";
  font-weight: 700;
  &:hover,
  &:focus {
    background-color: #486baf;
    color: #fff;
  }
}

.flatButtonOutlined {
  height: auto;
  padding: 14px 15px;
  font-size: 18px;
  line-height: 1;
  color: #486baf;
  background-color: #fff;
  border: 2px solid #486baf;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: hendersonBasic;
  font-weight: 600;
  &:hover,
  &:focus {
    background-color: #fff;
    color: #486baf;
  }
}

// Flat Buttons End

// Social Buttons Start
.socialButton {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: auto;
  height: 60px;
  padding: 14px 16px !important;
  border-radius: 10px !important;
  width: 165px;
  box-sizing: border-box;
  * {
    font-weight: 500;
    font-family: Roboto, sans-serif;
    font-size: 1.25rem;
  }
}
.socialButtonIcon {
  margin-right: 16px;
}

.btn-google {
  display: flex !important;
  line-height: 1;
  color: #757575;
  border-color: rgb(238, 238, 238) !important;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168) !important;
  &:hover,
  &:focus {
    color: rgba(0, 0, 0, 0.54) !important;

    outline: none !important;
    border-color: rgb(238, 238, 238) !important;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2), 0px 2px 3px rgba(0, 0, 0, 0.25);
  }
  & > :first-child {
    margin-right: 16px !important;
  }
  & > * {
    padding: 0px !important;
    padding-top: 2px !important;
  }
}
.btn-facebook {
  font-weight: 500;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  background-color: #1877f2;
  border: none;
  color: #fff;
  &:hover,
  &:focus {
    outline: none;
    color: #fff;
    background-color: #1877f2;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
    //   0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  & > * {
    padding: 0px !important;
    padding-bottom: 2px !important;
  }
}
.btn-apple {
  display: flex !important;
  align-items: center !important;
  background-color: #000;
  color: #fff;
  &:hover,
  &:focus {
    outline: none;
    color: #fff;
    background-color: #000;
    // box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.084),
    //   0px 2px 3px rgba(0, 0, 0, 0.168);
  }
  & > * {
    padding: 0px !important;
    padding-bottom: 2px !important;
  }
}
// Social Buttons End

// actb styling
.atcb_button_wrapper {
  button:first-child {
    height: auto;
    border: none;
    transform: none !important;
    padding: 15px 15px;
    font-size: 18px;
    line-height: 1;
    background-color: #486baf;
    // border: 2px solid #486baf;
    color: #fff;
    border-radius: 6px;
    font-family: "tradeGothicBold";
    font-weight: 700;

    width: fit-content;
    border-radius: 50px;
    padding-left: 45px;
    padding-right: 45px;

    &:hover,
    &:focus {
      background-color: #486baf;
      color: #fff;
    }
  }
}
