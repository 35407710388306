.rewardItem {
  .rewardItemHead {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .rewardItemBody {
    display: flex;
    gap: 4px;
  }
}
.availableRewardsList {
  & > *:not(:last-child) {
    margin-bottom: 14px;
    border-bottom: 1px solid #e4e4e4;
    padding-bottom: 14px;
  }
}
