// Dashbaord.js start
.dashContainer {
  background-image: url("https://liquidmobileiv.com/wp-content/uploads/2021/10/hp-hero@2x-scaled.jpg");
  background-position: left;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 120px - 280px);
  @media screen and (max-width: 768px) {
    background-attachment: initial;
    // background-attachment: fixed; // fix for a issue, select 2 guests from dropdown on mobile
  }
}

.dashboardDropdown {
  .ant-dropdown {
    top: 90px !important;
  }
}

.dashGuestContainer {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
}

.dashHeaderTitle {
  // width: 85%;
  display: flex;
  align-self: flex-start;
  font-size: 40px;
  padding-left: 5%;
  margin: 2% 0% 1% 0%;
}

.dashCancellationPolicyLink {
  color: "#486BAF";
  text-decoration: underline;
  cursor: pointer;
  font-size: 16px;
}

.dashFooterContainer {
  background: #fff;
  min-height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  // display: flex;
  // justify-content: center;
  // align-items: center;

  & > * {
    display: inline;
    margin: 0px;
    font-size: 20px;
  }
  @media screen and (max-width: 768px) {
    display: block;
    height: auto;
    padding: 20px;
    & > * {
      font-size: 18px;
    }
    // flex-direction: column;
    // height: fit-content;
    // align-items: flex-start;
  }
}

.dashFooterBoldText {
  font-weight: bold;
  padding: 4px;
}

.dashFooterBlueText {
  color: #486baf;
  cursor: pointer;
  padding: 4px;
}

// Dashboard.js end

// form.js start
.dashFormContainer {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .ant-form-item {
    margin: 0 !important;
  }
}

.dashForm {
  flex: 1;
  width: 100%;
  padding: 0px 16px;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 14px;
  background: #fff;

  @media screen and (max-width: 992px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @media screen and (max-width: 768px) {
    padding-top: 24px;
  }
}

.dashFormMainRow {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 120px;
  @media screen and (max-width: 768px) {
    margin-bottom: 30px;
  }
}

.dashFormGuestRows {
  padding: 40px 10px 16px 10px;
  position: relative;
  width: 100%;
  border-top: 2px solid #dbdfea;
}

.dashFormGuestRow {
  display: flex;
  align-items: center;
  // justify-content: space-between;
  background: #fff;
  width: 100%;
  min-height: 110px;
}

.dashFormMakePaymentBtn {
  margin: 0;
  width: 80px;
  text-align: center;
  font-size: 14px;
  position: absolute;
  left: 8px;
  font-family: tradeGothic;
  font-weight: bold;
  color: #486baf;
  top: 10px;
  left: -10px;
}

.dashFormRemoveBtn {
  color: #d83737;
  font-size: 16px;
  font-family: tradeGothic;
  cursor: pointer;
  margin-bottom: 0;
}
.verifyBtn {
  color: #486baf;
  font-size: 16px;
  font-family: tradeGothic;
  cursor: pointer;
}
.verifiedText {
  color: #36c477;
  font-size: 16px;
  font-family: tradeGothic;
}
.blueLoader {
  color: #486baf !important;
}
// form.js end

// username popup start
.usernamePopup {
  max-width: 420px !important;
}
// username popup end

.policyLinksContainer {
  display: flex;
  align-items: center;
  gap: 16px;
}
