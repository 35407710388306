header.appHeader {
  background: #fff;
  padding: 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
  position: relative;
}

.headerContent {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
  padding: 0px 60px;
  @media screen and (max-width: 768px) {
    padding: 0px;
    .logoImage {
      margin-bottom: -28px;
    }
  }
  p {
    font-size: 26px;
    margin: 0px 16px 0px 16px;
    cursor: pointer;
    font-family: rift;
  }
}

.headerTopRight {
  background: #161c32;
  height: 30px;
  width: 40%;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 0px 0px 0px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 72px;
  p,
  a {
    font-size: 16px;
    margin: 0px 16px;
    color: #fff;
    font-family: tradeGothic;
    font-weight: bold;
    cursor: pointer;
  }
  * {
    line-height: 1;
  }

  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 0px;
    position: fixed;
    z-index: 999;
    padding-right: 2px;
  }
}

// .ant-layout-header{
//   line-height: 1;
// }

.appHeaderUserNav {
  line-height: 1;
}

.hamburderIcon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerAvatar {
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #88ebc2;
  & > :first-child {
    font-family: "tradeGothic";
    font-weight: 700;
    color: #fff;
  }
}
