.sidebarWrapper {
  width: 100%;
  background-color: transparent;
  border-right: 0px;
  li.ant-menu-item {
    border-radius: 4px;
    margin: 0px !important;
    height: 50px;
    border-bottom: 1px solid #e4e9f3;

    display: flex;
    align-items: center;
    &.ant-menu-item-selected {
      background-color: #d9e1ee;
      .ant-menu-title-content {
        font-weight: 700;
        color: #000;
      }
    }
    .ant-menu-title-content {
      font-family: "tradeGothic";
      font-size: 17px;
    }
  }
}

.sideMenuButton {
  font-family: "tradeGothic";
  font-size: 17px;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  color: rgba(0, 0, 0, 0.85) !important;
  border: none;
}

// mobile dropdown styles
.menuDropdown {
  border-radius: 4px;
  border: 2px solid #dbdfea;
  .ant-select-selection-item {
    font-weight: 700;
    font-family: "tradeGothic", sans-serif;
  }
  div.ant-select-selector {
    height: auto !important;
    padding: 10px 14px !important;
  }
}
